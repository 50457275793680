$(document).ready(function () {
	var $menuproductos = $('#menuproductos');
	$menuproductos.hover(function () {
		$(this).dropdown('toggle');
	}, function () {
		if ($(this).next('.dropdown-menu').filter(function() { return $(this).is(":hover"); }).length == 0) {
			$(this).dropdown('toggle');
		}
	});
	$menuproductos.next('.dropdown-menu').hover(function () {
		//$(this).dropdown('toggle');
	}, function () {
		$(this).dropdown('toggle');
	});
});